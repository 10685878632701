import tw from "twin.macro";
import styled from 'styled-components'

export const MastheadWrapper = styled.div`
    ${tw`text-white max-w-full overflow-hidden md:p-0`}
    .masthead-wrapper{
${tw`lg:pt-[58px] md:pt-[88px] pb-0 min-h-screen flex items-center pt-[100px]`}
}}
    svg.infinite{
        *{
            transition:all 1s ease;
        }
        .circle{
            animation:draw-circle 10s linear infinite forwards;
        }
        .line-1{
            animation:draw-line-1 10s linear infinite forwards;
        }
        .line-2{
            animation:draw-line-2 10s linear infinite forwards;
        }
    }
    .stroke-line-Mb{
        ${tw`hidden md:block md:mt-[-164px]`}
    }
    svg{
        ${tw`w-full`}
    }
`

export const ContainerSvgDraw = styled.div`${tw`relative mb-6`}`

export const Icon3D = styled.div`${tw`absolute max-w-[1142px] max-h-[670px] h-auto w-full top-[41%] left-[52%] md:top-[60%] md:left-1/2 transition-all opacity-0 duration-1000`}
    transform: translate(-50%,0);
    @media screen and (max-width: 767px){
        transform: translate(-50%,150px);    
    }
    &.infinite{
        transform:translate(-50%,-50%);
        @media screen and (max-width: 767px){
            transform:translate(-50%,-50%);
        }
        ${tw`opacity-100`}
    }
    video{
        ${tw`w-full max-h-[640px] h-full`}
    }
`

export const WelcomeText = styled.div`${tw`absolute top-1/4 opacity-0 left-0 uppercase text-grays font-bold font-manrope md:static md:top-[unset] md:left-[unset] md:mt-3`}
    transform: translate(0,-50%);
    transition:all .325s linear;
    @media screen and (max-width:767px){
        transform: translate(0);
        br{
            ${tw`block md:hidden`}
        }
     }
`

export const WelcomeDescription = styled.div`${tw`text-white text-description font-manrope font-bold max-w-[348px] w-full top-1/4 lg:top-[unset] lg:bottom-0 right-0 lg:right-[unset] lg:left-0 absolute md:static md:bottom-[unset] md:left-[unset] opacity-0`}
     transform: translate(0,-50%);
     @media screen and (max-width:767px){
        transform: translate(0)
     }
     p{
        &:nth-child(1){
            ${tw`md:hidden`}
        }
        &:nth-child(2){
            ${tw`hidden md:block`}
        }
     }
`

export const HeadingMasthead = styled.h1`${tw`text-mutant-h2 lg:text-mutant-h2-mobile md:text-mutant-h3`}`

export const PepperdexTextWrapper = styled.div` ${tw`relative text-center md:-mx-3`}
    &>div{
        ${tw`opacity-0 transition-all duration-1000 delay-300 pb-8 `}
        transform:translateY(150px);
    }
    &.infinite{
        &>div{
            ${tw`opacity-100`}
            transform:translate(0);
        }
    }
    &:before{
        content:'';
        transform:translateX(-50%);
        animation:draw-line-bottom 1s linear;
        ${tw`absolute bg-white opacity-[0.08] bottom-0 left-1/2 w-full h-[1px]`}
    }
`
