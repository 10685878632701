import { createGlobalState } from 'react-hooks-global-state';

const { setGlobalState, useGlobalState } = createGlobalState({
    overflow: false,
    navbar:false,
    wallet:false,
    popup:'',
    wallet_key: null
});

export const setOverflowState = (value) => {
    setGlobalState('overflow', value);
};

export const setNavbarState = (value) => {
    setGlobalState('navbar', value);
};

export const setWallet = (value) => {
    setGlobalState('wallet', value);
};

export const setPopUp = (value) => {
    setGlobalState('popup', value);
};

export const setWalletKey = (value) => {
    setGlobalState('wallet_key',value)
}


export { useGlobalState };
