import tw from 'twin.macro'
import styled from 'styled-components'

export const Root = styled.section`
    ${tw`relative overflow-hidden h-screen`}
    .hxro-background-overlay-navbar{
        ${tw`absolute w-full h-full bg-black bg-opacity-60 bg-cover z-[-1] ease-linear delay-100 duration-300 z-[2] transition-opacity opacity-100`}
    }
    ${(props)=>{
        if(props.$overflow){
            return tw`overflow-hidden`
        }
    }}
`

export const AoenikHeading = styled.h1`
    ${tw`font-normal   lg: md:text-center leading-[100%]`}
`

export const Wrapper = styled.div`
    ${tw`w-full mx-auto relative`}
    canvas{
        ${tw`w-full h-full`}
    }
    .jquery-ripples{
        ${tw`md:!h-auto md:pb-[120px]`}
    }
    .hxro-social-component{
        ${tw`md:text-center text-right mt-[32px] absolute bottom-[32px] left-[50%] max-w-[1386px] w-full px-[32px]`}
        transform:translateX(-50%);
        .hxro-social-items{
            ${tw`mx-2 transition-all inline-block`}
            transform:scale(1);
            &:hover{
                transform:scale(1.2);
            }
            svg{
            ${tw`w-[25px] h-[25px]`}            
            }
        }
    }
`

export const Container = styled.div`
    ${tw`w-full max-w-[1440px] mx-auto px-[20px] lg:px-[32px] md:px-3 box-border`}
`

export const ButtonPrimary = styled.a`
    ${tw`mt-[56px] text-[18px] w-[200px] border-2 border-solid border-white px-[20px] py-[16px] text-white no-underline block text-center hover:bg-white hover:text-black transition-all duration-300 md:mx-auto md:mt-[16px]` }
`
