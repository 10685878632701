import home from "../data/home"
import Masthead from "../components/home/Masthead/index.jsx";
import About from "../components/home/About/index.jsx";
// import {useEffect} from "react";
// import gsap from "gsap";
// import ScrollTrigger from "gsap/ScrollTrigger";
//
// gsap.registerPlugin(ScrollTrigger);

const Home = () => {

    return <>
            <Masthead data={home.masthead}/>
            <About data={home.about}/>
    </>
}

export default Home
