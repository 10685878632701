export const fontFaces = `
@font-face {
    font-family: 'Orbitron';
    src: url('./fonts/Orbitron/hinted-Orbitron-Bold.eot');
    src: local('Orbitron Bold'), local('Orbitron-Bold'),
        url('./fonts/Orbitron/hinted-Orbitron-Bold.eot?#iefix') format('embedded-opentype'),
        url('./fonts/Orbitron/hinted-Orbitron-Bold.woff2') format('woff2'),
        url('./fonts/Orbitron/hinted-Orbitron-Bold.woff') format('woff'),
        url('./fonts/Orbitron/hinted-Orbitron-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Orbitron';
    src: url('./fonts/Orbitron/hinted-Orbitron-ExtraBold.eot');
    src: local('Orbitron ExtraBold'), local('Orbitron-ExtraBold'),
        url('./fonts/Orbitron/hinted-Orbitron-ExtraBold.eot?#iefix') format('embedded-opentype'),
        url('./fonts/Orbitron/hinted-Orbitron-ExtraBold.woff2') format('woff2'),
        url('./fonts/Orbitron/hinted-Orbitron-ExtraBold.woff') format('woff'),
        url('./fonts/Orbitron/hinted-Orbitron-ExtraBold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Orbitron';
    src: url('./fonts/Orbitron/hinted-Orbitron-Regular.eot');
    src: local('Orbitron Regular'), local('Orbitron-Regular'),
        url('./fonts/Orbitron/hinted-Orbitron-Regular.eot?#iefix') format('embedded-opentype'),
        url('./fonts/Orbitron/hinted-Orbitron-Regular.woff2') format('woff2'),
        url('./fonts/Orbitron/hinted-Orbitron-Regular.woff') format('woff'),
        url('./fonts/Orbitron/hinted-Orbitron-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Orbitron';
    src: url('./fonts/Orbitron/hinted-Orbitron-Black.eot');
    src: local('Orbitron Black'), local('Orbitron-Black'),
        url('./fonts/Orbitron/hinted-Orbitron-Black.eot?#iefix') format('embedded-opentype'),
        url('./fonts/Orbitron/hinted-Orbitron-Black.woff2') format('woff2'),
        url('./fonts/Orbitron/hinted-Orbitron-Black.woff') format('woff'),
        url('./fonts/Orbitron/hinted-Orbitron-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Orbitron';
    src: url('./fonts/Orbitron/hinted-Orbitron-Medium.eot');
    src: local('Orbitron Medium'), local('Orbitron-Medium'),
        url('./fonts/Orbitron/hinted-Orbitron-Medium.eot?#iefix') format('embedded-opentype'),
        url('./fonts/Orbitron/hinted-Orbitron-Medium.woff2') format('woff2'),
        url('./fonts/Orbitron/hinted-Orbitron-Medium.woff') format('woff'),
        url('./fonts/Orbitron/hinted-Orbitron-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Orbitron';
    src: url('./fonts/Orbitron/hinted-Orbitron-SemiBold.eot');
    src: local('Orbitron SemiBold'), local('Orbitron-SemiBold'),
        url('./fonts/Orbitron/hinted-Orbitron-SemiBold.eot?#iefix') format('embedded-opentype'),
        url('./fonts/Orbitron/hinted-Orbitron-SemiBold.woff2') format('woff2'),
        url('./fonts/Orbitron/hinted-Orbitron-SemiBold.woff') format('woff'),
        url('./fonts/Orbitron/hinted-Orbitron-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Manrope';
    src: url('./fonts/Manrope/hinted-Manrope-ExtraBold.eot');
    src: local('Manrope ExtraBold'), local('Manrope-ExtraBold'),
        url('./fonts/Manrope/hinted-Manrope-ExtraBold.eot?#iefix') format('embedded-opentype'),
        url('./fonts/Manrope/hinted-Manrope-ExtraBold.woff2') format('woff2'),
        url('./fonts/Manrope/hinted-Manrope-ExtraBold.woff') format('woff'),
        url('./fonts/Manrope/hinted-Manrope-ExtraBold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Manrope';
    src: url('./fonts/Manrope/hinted-Manrope-SemiBold.eot');
    src: local('Manrope SemiBold'), local('Manrope-SemiBold'),
        url('./fonts/Manrope/hinted-Manrope-SemiBold.eot?#iefix') format('embedded-opentype'),
        url('./fonts/Manrope/hinted-Manrope-SemiBold.woff2') format('woff2'),
        url('./fonts/Manrope/hinted-Manrope-SemiBold.woff') format('woff'),
        url('./fonts/Manrope/hinted-Manrope-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Manrope';
    src: url('./fonts/Manrope/hinted-Manrope-Bold.eot');
    src: local('Manrope Bold'), local('Manrope-Bold'),
        url('./fonts/Manrope/hinted-Manrope-Bold.eot?#iefix') format('embedded-opentype'),
        url('./fonts/Manrope/hinted-Manrope-Bold.woff2') format('woff2'),
        url('./fonts/Manrope/hinted-Manrope-Bold.woff') format('woff'),
        url('./fonts/Manrope/hinted-Manrope-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Manrope';
    src: url('./fonts/Manrope/hinted-Manrope-Medium.eot');
    src: local('Manrope Medium'), local('Manrope-Medium'),
        url('./fonts/Manrope/hinted-Manrope-Medium.eot?#iefix') format('embedded-opentype'),
        url('./fonts/Manrope/hinted-Manrope-Medium.woff2') format('woff2'),
        url('./fonts/Manrope/hinted-Manrope-Medium.woff') format('woff'),
        url('./fonts/Manrope/hinted-Manrope-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Manrope';
    src: url('./fonts/Manrope/hinted-Manrope-ExtraLight.eot');
    src: local('Manrope ExtraLight'), local('Manrope-ExtraLight'),
        url('./fonts/Manrope/hinted-Manrope-ExtraLight.eot?#iefix') format('embedded-opentype'),
        url('./fonts/Manrope/hinted-Manrope-ExtraLight.woff2') format('woff2'),
        url('./fonts/Manrope/hinted-Manrope-ExtraLight.woff') format('woff'),
        url('./fonts/Manrope/hinted-Manrope-ExtraLight.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Manrope';
    src: url('./fonts/Manrope/hinted-Manrope-Regular.eot');
    src: local('Manrope Regular'), local('Manrope-Regular'),
        url('./fonts/Manrope/hinted-Manrope-Regular.eot?#iefix') format('embedded-opentype'),
        url('./fonts/Manrope/hinted-Manrope-Regular.woff2') format('woff2'),
        url('./fonts/Manrope/hinted-Manrope-Regular.woff') format('woff'),
        url('./fonts/Manrope/hinted-Manrope-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Manrope';
    src: url('./fonts/Manrope/hinted-Manrope-Light.eot');
    src: local('Manrope Light'), local('Manrope-Light'),
        url('./fonts/Manrope/hinted-Manrope-Light.eot?#iefix') format('embedded-opentype'),
        url('./fonts/Manrope/hinted-Manrope-Light.woff2') format('woff2'),
        url('./fonts/Manrope/hinted-Manrope-Light.woff') format('woff'),
        url('./fonts/Manrope/hinted-Manrope-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}


`
