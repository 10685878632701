import tw from 'twin.macro'
import styled from "styled-components";

export const PopUpWrap = styled.div`
    ${tw`fixed top-[0] left-[0] w-screen h-screen justify-center items-center z-10`}
    ${props => {
        if(props.$active){
            return {...tw`flex`}
        }else{
            return {...tw`hidden`}
        }
    }}
`

export const PopUpContain = styled.div`
    ${tw`relative max-w-[538px] mx-auto px-[27px] py-[27px] h-[641px]`}
    z-index: 2;
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(29px);
    /* Note: backdrop-filter has minimal browser support */
    animation: fade-in-top 0.6s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
    border-radius: 28px;
`

export const PopUpBg = styled.div`
    ${tw`absolute top-[0] left-[0] cursor-pointer w-full h-full bg-black opacity-[0.86]`}
    z-index: 1;
`

export const PopUpContainClose = styled.div`
    ${tw`ml-auto cursor-pointer w-[27px]`}
`


export const PopUpWrapContain = styled.div`
    ${tw`px-[35px] md:px-[0]`}
`

export const PopUpContainLogo = styled.div`
    ${tw`text-center mb-[25.88px]`}
    svg{
        ${tw`w-[133.36px] h-[57.15px]`}
    }
`

export const PopUpContainContent = styled.div`
    h3{
        ${tw`font-bold text-center text-white mb-[38px] md:mb-[20px]`}
    }
    p{
        ${tw`text-white font-normal text-center mb-[34px] md:mb-[30px]  leading-[21px]`}
        a{
            ${tw`text-white font-normal underline`}
        }
    }
`

export const PopUpContainButton = styled.div`
    ${tw`text-center`}
    a{
        ${tw`inline-flex 
             justify-center  
             items-center 
             w-[225px] 
             h-[46px]
             text-white
             bg-black
             
             
             font-bold
             no-underline
        `}
    }
`
