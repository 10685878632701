import {
    PopUpBg,
    PopUpContain, PopUpContainButton,
    PopUpContainClose,
    PopUpContainContent,
    PopUpContainLogo,
    PopUpWrapContain
} from "./style.js";
import {setPopUp} from "../../../stores.js";
import {Container, Wrapper} from "../../../styles/global.js";
import {Close, PopupLogo} from "../Icon/index.jsx";

const PopUpContent = ({data}) => {
    return <>
        <PopUpBg onClick={(event)=>{
            event.preventDefault()
            setPopUp(false)
        }} />
        <Wrapper>
            <Container>
                <PopUpContain>
                    <PopUpContainClose onClick={(event)=>{
                        event.preventDefault()
                        setPopUp(false)
                    }}>
                        <Close />
                    </PopUpContainClose>
                    <PopUpWrapContain>
                        <PopUpContainContent>
                            <h3 dangerouslySetInnerHTML={{__html: data.title}} />
                            <p dangerouslySetInnerHTML={{__html:data.content}} />
                        </PopUpContainContent>
                        <PopUpContainButton>
                            <a href={data.cta.url}
                               target={'_blank'}
                               rel={'noreferrer nofollow'}
                               dangerouslySetInnerHTML={{__html: data.cta.title}}
                            />
                        </PopUpContainButton>
                    </PopUpWrapContain>
                </PopUpContain>
            </Container>
        </Wrapper>
    </>
}

export default PopUpContent
