import styled from "styled-components";
import tw from 'twin.macro'

export const FooterWrap = styled.footer`
    a{${tw`text-black font-manrope no-underline `}}
    ${tw`pt-[40px] pb-[40px] xxl:pt-[20px] xxl:pb-[20px] border-t border-solid border-black bg-[#FBFBFB] md:pb-[120px]`}
`
export const FooterContain = styled.div`
    ${tw`flex xxl:flex-col-reverse relative`}
`
export const TextTyping = styled.div`
    ${tw`absolute top-[50%] left-[50%] text-[40px] xxl:text-[24px] font-bold leading-[96%] uppercase tracking-[1.2px] xxl:tracking-[0.72px]`}
    transform:translate(-50%, -50%);
`
export const FooterHeading = styled.h3`
    ${tw`flex text-black text-mutant-h3 md:text-description font-extrabold uppercase tracking-[2.1px] opacity-0 transition-all duration-500 mb-3 md:mb-0`}
    transform: translateX(150px);
    &.infinite{
        ${tw`opacity-100`}
        transform: translateX(0);
    }
    
`
export const TextSign = styled.span`
    ${tw`font-manrope text-[#00000066] font-bold leading-[110%] uppercase tracking-[-0.16px] xxl:pt-[20px]`}
`
export const FooterSocialTop = styled.div`
    ${tw`flex justify-between xxl:flex-col-reverse xxl:pb-[40px]`}
`
export const FooterInnerTitle = styled.div`
    ${tw`absolute xxl:relative xxl:pb-[40px] top-0 left-[50%] z-10 font-manrope text-[16px] font-bold leading-[110%] uppercase tracking-[-0.16px] text-center`}
    transform:translateX(-50%);
    @media screen and (max-width:1280px){
        top: unset;
        left: unset;
        transform: none;
    }
`
export const FooterSocial = styled.div`
    flex:0 0 100%;
    width:100%;  
    ${tw` xxl:pb-[32px] flex justify-between`}
    @media screen and (max-width:768px) {
        ${tw`flex-col`}
    }
`

export const FooterLeft = styled.div`
    ${tw`flex flex-col gap-y-6 xxl:pb-[40px]`}
    flex:0 0 50%;
    width:50%;
    @media screen and (max-width:1280px) {
        flex:0 0 100%;
        width:100%;  
    }
`

export const FooterPepperDex = styled.div`
    ${tw`pr-[40px] xxl:pr-0 xxl:pt-[20px]`}
    flex:0 0 50%;
    width:50%;
    @media screen and (max-width:1280px){
        flex:0 0 100%;
        width:100%;  
    }
`
export const FooterInner = styled.div`
    ${tw` relative`}
`
export const FooterInnerBg = styled.div`
    ${tw` relative`}
    svg {
        ${tw` w-full`}
    }
    .bg-line-Mb{
        ${tw`hidden md:block`}
    }
    svg.infinite{
        .circle{
            animation:draw-circle 10s linear infinite forwards;
        }
        .line-1{
            animation:draw-line-1 10s linear infinite forwards;
        }
        .line-2{
            animation:draw-line-2 10s linear infinite forwards;
        }
    }
`

export const SocialItem = styled.div`
  ${tw`text-white relative w-full h-full pr-[1px]`}
  a {
    ${tw`text-[#00000066] no-underline text-[10px] font-bold leading-[110%] tracking-[-0.1px] inline-flex items-center uppercase w-full h-full items-end pb-[10px] pl-[10px] relative hover:text-black`}
    transition: all 0.3s ease;
    span {
      ${tw`mr-[15px]`}
    }
    svg {
      ${tw`absolute top-[50%] left-[50%] xxl:w-[20px] xxl:h-[20px]`}
      transform: translate(-50%, -50%);
    }
  }
`;
export const FooterSocialInner = styled.div`
    ${tw`flex h-[61px] w-full mt-auto`}
`;

export const LinkSocial = styled.a`
  ${tw`border border-solid bg-transparent`}
  border: none;
  outline: none;
  position: relative;
  border-radius: 0;
  background: #1717170D;
`;

export const PythSponsor = styled.div`

    ${tw`flex gap-x-4 items-center`}
`

export const FooterRight = styled.div`
    ${tw`flex flex-col gap-y-6 xxl:pb-[40px] justify-end`}
    @media screen and (max-width:1280px){
        flex:0 0 100%;
        width:100%;  
    }
    @media screen and (max-width:768px) {
        ${tw`items-center`}
    }
`

export const ButtonSignUp  = styled.div`
    ${tw`cursor-pointer w-[264px] md:w-full xxl:pt-[40px] xxl:relative xxl:right-[unset] xxl:left-0`}
    max-width: 264px;
    @media screen and (max-width:1280px){
        bottom: unset;
        right: unset;
        transform: none;
    }
    @media screen and (max-width:768px){
        flex: 0 0 100%;
        max-width: 100%;
    }
    a{
        ${tw`no-underline`}
    }
`
