import {ButtonWrap} from "./style.js";

const Button = (props) => {
    let children = props.children
    let transfer = props
    delete props.transfer
    return <>
        <ButtonWrap {...transfer}>
            <span>{children}</span>
        </ButtonWrap>
    </>
}

export default Button
