import {
  HeaderContain,
  HeaderLinkItem,
  HeaderLinks,
  HeaderText,
  HeaderWrap,
  MenuBtn,
  ButtonSignUp,
  LinkSocial
} from "./style";
import { Container, Wrapper } from "../../../styles/global";
import Button from "../../global/Button/index.jsx";
import * as Icon from "../Icon/index.jsx";
import { Hamburger } from "../Icon/index.jsx";
import { setNavbarState, setWallet, useGlobalState } from "../../../stores.js";
import { useEffect, useRef } from "react";
import usePreventScrolling from "../../../stores/usePreventScrolling.jsx";

const Header = ({ data }) => {
  let unique = Date.now();
  const [value] = useGlobalState("navbar");
  let header = useRef(null);

  useEffect(() => {
    let walletName = window.localStorage.getItem("walletName");
    if (walletName) {
      setWallet(true);
    }
  });
  usePreventScrolling({ active: value })
  return (
    <>
      <HeaderWrap $active={value} ref={header}>
        <Wrapper>
          <Container>
            <HeaderContain>
              <HeaderText>
                <Icon.LogoMB />
                <span dangerouslySetInnerHTML={{ __html: data.title }} />
              </HeaderText>
            <HeaderLinks>
                {data.navbar_desktop.items.map((item, index) => {
                return (
                    <HeaderLinkItem key={`header-item-${index}-${unique}`}>
                        <a
                            href={item.url}
                            target={
                            item.target && item.target !== ""
                                ? item.target
                                : "_blank"
                            }
                            rel={"nofollow"}
                        >   <span>{item.number}</span>
                            <span>{item.title}</span>
                        </a>
                    </HeaderLinkItem>
                );
                })}
                <HeaderLinkItem>
                    <LinkSocial
                        href={data.social.discord.url}
                        target={"_blank"}
                        rel={"nofollow"}
                    >
                        <span>{data.social.discord.text}</span>
                        <Icon.Discord />
                    </LinkSocial>
                </HeaderLinkItem>
                <HeaderLinkItem>
                    <LinkSocial
                        href={data.social.twitter.url}
                        target={"_blank"}
                        rel={"nofollow"}
                    >
                        <span>{data.social.twitter.text}</span>
                        <Icon.Twitter />
                    </LinkSocial>
                </HeaderLinkItem>
            </HeaderLinks>
              <ButtonSignUp>
                <a href={data.button.url} target={'_blank'}>
                    <Button $isSecondary={true}>
                        {data.button.text}
                        <Icon.ArrowRight/>
                    </Button>
                </a>
              </ButtonSignUp>
              <MenuBtn
                onClick={() => {
                  setNavbarState(!value);
                }}
              >
                <Hamburger active={value} />
              </MenuBtn>
            </HeaderContain>
          </Container>
        </Wrapper>
      </HeaderWrap>
    </>
  );
};

export default Header;
