import Header from "./components/global/Header/index.jsx";
import Main from "./components/global/Main/index.jsx";
import {Root} from "./styles/global.js";
import {setNavbarState, useGlobalState} from "./stores.js";
import {useEffect, useRef} from "react";
import Navbar from "./components/global/Navbar/index.jsx";
import Aos from "aos";
import "aos/dist/aos.css";
import PopUp from "./components/global/PopUp/index.jsx";
// import {Scrollbar} from 'smooth-scrollbar-react';
import Scrollbar from "smooth-scrollbar";
import gsap from "gsap";
import SmoothScrollbar, { ScrollbarPlugin } from 'smooth-scrollbar';
import ScrollTrigger from "gsap/ScrollTrigger";
import Footer from "./components/global/Footer/index.jsx";
import {DisableScrollPlugin, AnchorPlugin} from "./stores/CustomizePluginScroll";

SmoothScrollbar.use(DisableScrollPlugin);
SmoothScrollbar.use(AnchorPlugin);

gsap.registerPlugin(ScrollTrigger);
const Template = ({data,children}) =>{

    const [value] = useGlobalState('navbar')
    const animate = useRef(null)
    const scroller = useRef();
    useEffect(()=>{
        if(animate?.current){
            Aos.init({ duration: 1000 });
        }
    },[animate])
    useEffect(() => {
        if(animate?.current){
            const scroller = document.querySelector(".scroller")
            const bodyScrollBar = Scrollbar.init(scroller,{
                damping:0.1,
                thumbMinSize:20,
                alwaysShowTracks:false,
                continuousScrolling:true,
                plugins:{
                    disableScroll: { direction: 'x' }
                }
            });
            bodyScrollBar.setPosition(0, 0);
            bodyScrollBar.track.xAxis.element.remove()
            bodyScrollBar.track.yAxis.element.remove()
            ScrollTrigger.scrollerProxy(scroller, {
                scrollTop(value) {
                    if (arguments.length) {
                        bodyScrollBar.scrollTop = value;
                    }
                    return bodyScrollBar.scrollTop;
                },
                getBoundingClientRect() {
                    return {top: 0, left: 0, width: window.innerWidth, height: window.innerHeight};
                },
                pinType: scroller.style.transform ? "transform" : "fixed"
            });

            bodyScrollBar.addListener(ScrollTrigger.update);

            // ScrollTrigger.default.defaults({scroller: ".scroller"})
            // This part is only neccessary if you're using ScrollTrigger's markers for testing:
            if (document.querySelector(".gsap-marker-scroller-start")) {
                const markers = gsap.utils.toArray('[class *= "gsap-marker"]');
                bodyScrollBar.addListener(({ offset }) =>
                    gsap.set(markers, { marginTop: -offset.y })
                );
            }

                // const scroller = document.querySelector(".scroller");



        }

    },[animate]);
    return <>
        <Root $overflow={value} ref={animate} className={'scroller'}>
            {
                <div className={`hxro-background-overlay-navbar ${value ? 'block' : 'hidden'}`} onClick={()=>{
                    setNavbarState(!value)
                }}></div>
            }
            <Header data={data.header} />
            <Navbar data={data.header} />
            <Main>{children}</Main>
            <PopUp data={{
                'default': data.popup,
                'trade': data.popupTrade
            }} />
            <Footer data={data.footer}/>
        </Root>
    </>
}

export default Template
