import tw from "twin.macro"
import styled from "styled-components";

export const ButtonWrap = styled.button`
    ${tw`border border-solid bg-transparent cursor-pointer`}
    ${(props) =>{
        if(props.$isSecondary){
            return {
                ...tw`
                    py-[18px] 
                    pl-[24px]
                    pr-[30px]
                    text-[16px] 
                    font-bold 
                    leading-[18px] 
                    tracking-[0.16px]
                    text-white
                    bg-accentsRed
                    flex
                    items-center
                    uppercase
                    border-[#FF3030]
                    w-full
                `,
                clipPath: "polygon(0% 0%,0% 100%,100% 100%,100% 100%,100% 30%,90% 0%)",
                "&:hover":{
                    "clip-path": "polygon(0% 0%,0% 100%,100% 100%,100% 100%,100% 0%,100% 0%)"
                },
                "@media screen and (max-width:1280px)": {
                    clipPath: "polygon(0% 0%,0% 100%,98% 100%,100% 68%,100% 100%,100% 0%)",
                    "&:hover":{
                        "clip-path": "polygon(0% 0%,0% 100%,100% 100%,100% 100%,100% 100%,100% 0%)"
                    },
                },
                "@media screen and (max-width:768px)": {
                    clipPath: "polygon(0% 0%,0% 100%,94% 100%,100% 70%,100% 100%,100% 0%)",
                    "&:hover":{
                        "clip-path": "polygon(0% 0%,0% 100%,100% 100%,100% 100%,100% 100%,100% 0%)"
                    },
                },
                "transition": "all 0.3s ease",
                
                "span":{
                    ...tw`flex items-center w-full`
                },
                "svg": {
                    ...tw`ml-auto`
                }
            }
        }
        if(props.$isPrimary){
            return {
                ...tw`  relative 
                        border-black 
                        rounded
                        after:bg-[#FFFFFF33]
                        text-black 
                        after:block 
                        after:z-[0]
                        after:absolute
                        after:w-full
                        after:h-full
                        after:top-[0]
                        after:left-[0]
                        py-[13px] 
                        px-[45px]
                        after:rounded
                    `,
                "&:after":{
                  "transition": "background 0.25s ease-out"  
                },
                "&:hover":{
                    ...tw`
                        border-white
                        text-white
                        after:bg-[#000000]
                    `
                },
                "span":{
                    ...tw`relative z-10 `
                },
            }
        }    
    }}
`
