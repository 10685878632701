import tw from "twin.macro";
import styled from 'styled-components'

export const AboutWrapper = styled.div`${tw`mt-[140px] md:mt-[100px] relative pt-[40px]`}
    &:before{
        content:'';
        transition:all 1s linear;
        transform:translateX(-50%);
        ${tw`absolute top-0 left-1/2 w-0 h-[1px] bg-white opacity-[0.08] w-0`}
    }
    &.infinite{
        &:before{
          ${tw`w-full`}
        }
    }
`

export const TitleAbout = styled.h2`${tw`text-mutant-h2 font-orbitron font-bold md:text-mutant-h2-mobile uppercase text-white`}
    transform:translateY(-20px);
`

export const LabelCircle = styled.div`${tw`text-mutant-body font-manrope uppercase tracking-[-0.16px] text-white font-bold absolute left-1/2 top-1/2 w-max`}
    transform:translate(-50%,-50%);
`

export const LabelCircleContainer = styled.div`${tw`absolute left-0 top-1/2 w-[calc(100% - 38px)] xl:relative xl:top-0 xl:w-full`}
    transform: translateY(-50%);
    @media screen and (max-width:1024px){
        transform: translateY(0)
    }
    .circle-desktop{
        ${tw`block md:hidden`}
    }
    .circle-mb{
        ${tw`hidden md:block`}
    }
    svg{
        transition:opacity .25s linear;
        ${tw`w-full opacity-0`}
    }
`

export const LabelCircleWrapper = styled.div`${tw`relative pr-[38px] h-full md:pr-0`}
   &:before{
    content:'';
    transition:all 1s linear;
    ${tw`absolute right-0 top-0 w-[1px] h-0 bg-white opacity-[0.08]`}
   }
   &.infinite{
    &:before{
        ${tw`h-full md:h-0`}
    }
   }
   svg.infinite{
        *{
            transition:all 1s ease;
        }
        .circle{
            animation:draw-circle 10s linear infinite forwards;
        }
        .line-1{
            animation:draw-line-1 10s linear infinite forwards;
        }
        .line-2{
            animation:draw-line-2 10s linear infinite forwards;
        }
    }
`

export const AboutContainer = styled.div`${tw`grid grid-cols-2 xl:grid-cols-1`}`

export const TitleCol = styled.div`${tw`col-span-1 relative`}`

export const ContentCol = styled.div`${tw`col-span-1 relative pl-[40px] xl:pl-0`}
    &.infinite{
            &>div{
                &:before{
                ${tw`w-full`}
            }
            }
            
        }
`

export const ContentSection = styled.div`
    ${tw`bg-black`}
    &:nth-child(1){
        @media screen and (max-width:768px){
            ${tw`pt-[20px] mt-[40px] relative`}
            &:before{
                content:'';
                transition: width .5s linear;
                ${tw`absolute right-0 top-0 w-0 h-[1px] bg-white opacity-[0.08]`}
            }
        }
    }
    &:nth-child(2){
        ${tw`pt-[20px] mt-[40px] relative xl:pb-[42px]`}
        &:before{
            content:'';
            transition: width .5s linear;
            ${tw`absolute right-0 top-0 w-0 h-[1px] bg-white opacity-[0.08]`}
        }
    }
    &:nth-child(3){
        ${tw`pt-[20px] mt-[40px] relative xl:pb-[42px]`}
        &:before{
            content:'';
            transition: width .5s linear;
            ${tw`absolute right-0 top-0 w-0 h-[1px] bg-white opacity-[0.08]`}
        }
    }
    &:nth-child(4){
        ${tw`pt-[20px] mt-[40px] relative xl:pb-[42px]`}
        &:before{
            content:'';
            transition: width .5s linear;
            ${tw`absolute right-0 top-0 w-0 h-[1px] bg-white opacity-[0.08]`}
        }
    }
`

export const ContentSectionWrapper = styled.div`${tw`flex flex-wrap`}`

export const LabelSection = styled.div`${tw`w-[175px] uppercase text-grays font-bold font-manrope tracking-[-0.16px]`}`

export const DescriptionContent = styled.div`${tw`text-white text-description font-manrope font-bold uppercase w-full max-w-[351px] xl:max-w-full xl:pl-0 xl:pt-[24px] pl-[24px] ml-auto mr-0`}`
