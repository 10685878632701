import {Container, Wrapper} from "../../../styles/global.js";
import {
    AboutContainer,
    AboutWrapper, ContentCol, ContentSection, ContentSectionWrapper, DescriptionContent,
    LabelCircle,
    LabelCircleContainer,
    LabelCircleWrapper, LabelSection,
    TitleAbout,
    TitleCol
} from "./styles.js";
import {useEffect, useRef, useState} from "react";
import {gsap} from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import Vivus from "vivus";

gsap.registerPlugin(ScrollTrigger);

const About = ({data}) =>{
    const AboutRef = useRef(null)
    const CircleLabel = useRef(null)
    const CircleLabelMb = useRef(null)
    const ContentArray = useRef([])
    const TitleLabel = useRef(null)
    const [infinite,setInfinite] = useState('')
    const [checkMedia,setCheckMedia] = useState(false)
    const svgDrawMb = useRef(null)

    useEffect(()=>{
        const scroller = document.querySelector(".scroller")
        let checkDevice = window.matchMedia('(min-width: 768px)').matches
        setInfinite(checkDevice)
        let positionTitle = ''
        checkDevice ? positionTitle = 'top +=50%': positionTitle= 'top +=160%'
        if(AboutRef?.current){
            let arrayContent = ContentArray.current
            let SVGAnimation
            gsap.set(TitleLabel.current,{opacity:0, x:'-150px'})
            gsap.to(TitleLabel.current, {
                x:0,
                opacity:1,
                scrollTrigger: {
                    trigger: AboutRef.current,
                    // markers: true,
                    scroller:scroller,
                    start: positionTitle,
                    onEnter: () =>{
                        setInfinite('infinite')
                    }
                }
            })
            if(checkDevice){
                gsap.to(CircleLabel.current, {
                    scrollTrigger: {
                        trigger: CircleLabel.current,
                        // markers: true,
                        scroller:scroller,
                        start: "top +=80%",
                        once:true,
                        onEnter: () =>{
                            CircleLabel.current.style.opacity = 1
                            SVGAnimation = new Vivus(CircleLabel.current, {duration: 100,type:'sync'}, null)
                            SVGAnimation.play(1,function(){
                                setTimeout(()=>{
                                    SVGAnimation.destroy()
                                    CircleLabel.current.classList.add('infinite')
                                },325)
                            })
                        }
                    }
                })
            }else{
                gsap.to(CircleLabelMb.current, {
                    scrollTrigger: {
                        trigger: CircleLabelMb.current,
                        // markers: true,
                        scroller:scroller,
                        start: "top +=160%",
                        once:true,
                        onEnter: () =>{
                            CircleLabelMb.current.style.opacity = 1
                            SVGAnimation = new Vivus(CircleLabelMb.current, {duration: 100,type:'sync'}, null)
                            SVGAnimation.play(1,function(){
                                setTimeout(()=>{
                                    SVGAnimation.destroy()
                                    CircleLabelMb.current.classList.add('infinite')
                                },325)
                            })
                        }
                    }
                })
            }

            if(arrayContent.length > 1 && checkDevice){
                if(arrayContent[0] && arrayContent[1] && arrayContent[2] && arrayContent[3]){
                    let offsetHeight = 100
                    gsap.set(arrayContent[0],{opacity:0})
                    arrayContent[0].style.transition = `all .325s linear`
                    gsap.to(arrayContent[0],{scrollTrigger: {
                            trigger: arrayContent[0],
                            // markers: true,
                            scroller:scroller,
                            start: "top +=80%",
                            onEnter: ()=>{
                                arrayContent[0].style.opacity = 1
                            }
                        }})
                    gsap.to(arrayContent[1], {
                        scrollTrigger: {
                            trigger: arrayContent[0],
                            // markers: true,
                            scroller:scroller,
                            start: "top +=70%",
                            end:'bottom +=40%',
                            onEnter: ()=>{
                                arrayContent[1].classList.add('infinite')
                            },
                            onUpdate: (self) =>{
                                arrayContent[0].style.opacity = 1 - self.progress
                                arrayContent[1].style.marginTop = `-${self.progress * offsetHeight}px`
                                arrayContent[1].style.paddingBottom = `${self.progress * offsetHeight}px`
                            }
                        }
                    })
                    gsap.to(arrayContent[2], {
                        scrollTrigger: {
                            trigger: arrayContent[1],
                            // markers: true,
                            scroller:scroller,
                            start: "top +=70%",
                            end:'bottom +=40%',
                            onEnter: ()=>{
                                arrayContent[2].classList.add('infinite')
                            },
                            onUpdate: (self) =>{
                                arrayContent[1].style.opacity = 1 - self.progress
                                arrayContent[2].style.marginTop = `-${self.progress * offsetHeight}px`
                                arrayContent[2].style.paddingBottom = `${self.progress * offsetHeight}px`
                            }
                        }
                    })
                    gsap.to(arrayContent[3], {
                        scrollTrigger: {
                            trigger: arrayContent[2],
                            // markers: true,
                            scroller:scroller,
                            start: "top +=70%",
                            end:'bottom +=40%',
                            onEnter: ()=>{
                                arrayContent[3].classList.add('infinite')
                            },
                            onUpdate: (self) =>{
                                arrayContent[2].style.opacity = 1 - self.progress
                                arrayContent[3].style.marginTop = `-${self.progress * offsetHeight}px`
                                arrayContent[3].style.paddingBottom = `${self.progress * offsetHeight}px`
                            }
                        }
                    })
                }

            }
        }
    },[])
    return <>
        <Wrapper id={'about'}>
            <Container>
                <AboutWrapper ref={AboutRef} className={infinite}>
                    <AboutContainer>
                        <TitleCol>
                            <LabelCircleWrapper className={infinite}>
                                <TitleAbout ref={TitleLabel}>
                                    {data.title}
                                </TitleAbout>
                                <LabelCircleContainer>
                                    {
                                        !checkMedia && <svg xmlns="http://www.w3.org/2000/svg" className={'circle-desktop'} width={641} height={248} viewBox="0 0 641 248" fill="none" ref={CircleLabel}>
                                            <line x1="4.37114e-08" y1="123.5" x2={641} y2="123.5" stroke="white" strokeOpacity="0.08" />
                                            <circle cx={320} cy={124} r={123} fill="#171717" />
                                            <path className={'circle'} d="M443 124C443 99.6317 435.762 75.8126 422.204 55.5644C408.645 35.3162 389.378 19.5516 366.847 10.2705C344.315 0.989465 319.534 -1.38978 295.648 3.43466C271.762 8.25911 249.848 20.0698 232.685 37.3684C215.522 54.6669 203.884 76.6737 199.247 100.597C194.61 124.52 197.184 149.281 206.642 171.739C216.1 194.197 232.016 213.34 252.37 226.738C272.724 240.137 296.599 247.188 320.967 246.996" stroke="white" strokeOpacity="0.08" strokeDasharray="10 10" />
                                        </svg>
                                    }
                                    <svg xmlns="http://www.w3.org/2000/svg" className={'circle-mb'} width={351} height={277} viewBox="0 0 351 277" fill="none" ref={CircleLabelMb}>
                                        <line x1="4.36815e-08" y1="137.5" x2={351} y2="137.5" stroke="white" strokeOpacity="0.08" />
                                        <circle cx="175.5" cy="138.5" r="137.5" fill="#171717" />
                                        <path className={'circle'} d="M313 138.5C313 111.305 304.936 84.7209 289.827 62.1091C274.718 39.4973 253.244 21.8736 228.119 11.4666C202.994 1.05951 175.347 -1.66344 148.675 3.64202C122.003 8.94749 97.5025 22.0431 78.2728 41.2728C59.0431 60.5025 45.9475 85.0027 40.642 111.675C35.3366 138.347 38.0595 165.994 48.4666 191.119C58.8736 216.244 76.4973 237.718 99.1091 252.827C121.721 267.936 148.305 276 175.5 276" stroke="white" strokeOpacity="0.08" strokeDasharray="10 10" />
                                    </svg>

                                    <LabelCircle>
                                        {data.label}
                                    </LabelCircle>
                                </LabelCircleContainer>
                            </LabelCircleWrapper>
                        </TitleCol>
                        <ContentCol className={infinite}>
                            {
                                data?.sideSection?.length > 0 && data.sideSection.map((item,index)=>{
                                    let unique = new Date()
                                    return <ContentSection className="content-section" key={`contentSection-${index}-${unique}`} ref={el => ContentArray.current[index] = el} >
                                        <ContentSectionWrapper>
                                            <LabelSection dangerouslySetInnerHTML={{__html: item.label}}/>
                                            <DescriptionContent dangerouslySetInnerHTML={{__html: item.content}} className={'description-content'}/>
                                        </ContentSectionWrapper>
                                    </ContentSection>
                                })
                            }
                        </ContentCol>
                    </AboutContainer>
                </AboutWrapper>
            </Container>
        </Wrapper>
    </>
}
 export default About
