import {
  FooterContain,
  FooterSocial,
  FooterWrap,
  FooterInner,
  FooterSocialTop,
  TextSign,
  FooterSocialInner,
  FooterHeading,
  FooterInnerBg,
  FooterInnerTitle,
  TextTyping,
  SocialItem,
  LinkSocial,
  ButtonSignUp,
  FooterPepperDex,
  FooterRight,
  FooterLeft,
  PythSponsor,
} from "./style.js";
import { Container, Wrapper } from "../../../styles/global";
import {
  FooterIcon,
  Discord,
  Twitter,
  ArrowRight,
  Pyth,
} from "../Icon/index.jsx";
import ReactTyped from "react-typed";
import Button from "../Button/index.jsx";
import { useEffect, useRef, useState } from "react";
import { gsap } from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import Vivus from "vivus";
import { Image } from "../../global/Image/index.jsx";

const Footer = ({ data }) => {
  const [checkMedia, setCheckMedia] = useState(false);
  const [showText, setShowText] = useState(false);
  const [infinite, setInfinite] = useState("");
  const [infiniteTitle, setInfiniteTitle] = useState("");
  const drawSVG = useRef(false);
  const drawSVGMb = useRef(false);
  const footerRef = useRef(null);
  useEffect(() => {
    let checkMediaQuery = window.matchMedia("(max-width: 767px)").matches;
    let checkPosition = "";
    setCheckMedia(checkMediaQuery);
    checkMediaQuery
      ? (checkPosition = "top +=160%")
      : (checkPosition = "top +=80%");
    if (footerRef?.current) {
      const scroller = document.querySelector(".scroller");
      gsap.to(footerRef.current, {
        scrollTrigger: {
          trigger: footerRef.current,
          start: checkPosition,
          // markers:true,
          scroller: scroller,
          scrub: 1,
          once: 1,
          onEnter: (self) => {
            setShowText(true);
            setInfiniteTitle("infinite");
            let SVGAnimation;
            if (drawSVG?.current && !checkMediaQuery) {
              SVGAnimation = new Vivus(
                drawSVG.current,
                { duration: 100, type: "sync" },
                null
              );
              SVGAnimation.play(1, function () {
                setInfinite("infinite");
                setTimeout(() => {
                  SVGAnimation.destroy();
                }, 325);
              });
            } else if (drawSVGMb?.current) {
              SVGAnimation = new Vivus(
                drawSVGMb.current,
                { duration: 100, type: "sync" },
                null
              );
              SVGAnimation.play(1, function () {
                setInfinite("infinite");
                setTimeout(() => {
                  SVGAnimation.destroy();
                }, 325);
              });
            }
          },
        },
      });
    }
  }, []);

  return (
    <>
      <FooterWrap ref={footerRef}>
        <Wrapper>
          <Container>
            <FooterContain>
              {/*<FooterPepperDex>*/}
              {/*    <FooterInner>*/}
              {/*<FooterInnerTitle dangerouslySetInnerHTML={{__html: data.title}}/>*/}
              {/*<FooterInnerBg>*/}
              {/*    {*/}
              {/*        !checkMedia ? <svg ref={drawSVG} className={`bg-line-Dt ${infinite}`} width="637" height="248" viewBox="0 0 637 248" fill="none" xmlns="http://www.w3.org/2000/svg">*/}
              {/*            <line className={'line-1'} x1="0.822199" y1="244.533" x2="636.782" y2="2.56857" stroke="#171717" strokeOpacity="0.1" strokeDasharray="10 10"/>*/}
              {/*            <circle cx="321" cy="124" r="123" fill="#FBFBFB"/>*/}
              {/*            <path className={'circle'} d="M444 124C444 99.6317 436.762 75.8126 423.204 55.5644C409.645 35.3162 390.378 19.5516 367.847 10.2705C345.315 0.989465 320.534 -1.38978 296.648 3.43466C272.762 8.25911 250.848 20.0698 233.685 37.3684C216.522 54.6669 204.884 76.6737 200.247 100.597C195.61 124.52 198.184 149.281 207.642 171.739C217.1 194.197 233.016 213.34 253.37 226.738C273.724 240.137 297.599 247.188 321.967 246.996" stroke="#171717" strokeOpacity="0.1" strokeDasharray="10 10"/>*/}
              {/*        </svg> : <></>*/}
              {/*    }*/}
              {/*    <svg ref={drawSVGMb} className={`bg-line-Mb ${infinite}`} width="359" height="248" viewBox="0 0 359 248" fill="none" xmlns="http://www.w3.org/2000/svg">*/}
              {/*        <line x1="0.822202" y1="196.533" x2="357.855" y2="60.6948" stroke="#171717" strokeOpacity="0.1" strokeDasharray="10 10"/>*/}
              {/*        <circle cx="182" cy="124" r="123" fill="#FBFBFB"/>*/}
              {/*        <path className={'circle'} d="M305 124C305 99.6317 297.762 75.8126 284.204 55.5644C270.645 35.3162 251.378 19.5516 228.847 10.2705C206.315 0.989465 181.534 -1.38978 157.648 3.43466C133.762 8.25911 111.848 20.0698 94.6847 37.3684C77.5216 54.6669 65.8835 76.6737 61.247 100.597C56.6104 124.52 59.1844 149.281 68.6423 171.739C78.1002 194.197 94.0157 213.34 114.37 226.738C134.724 240.137 158.599 247.188 182.967 246.996" stroke="#171717" strokeOpacity="0.1" strokeDasharray="10 10"/>*/}
              {/*    </svg>*/}
              {/*    <TextTyping>*/}
              {/*        {*/}
              {/*            showText && <ReactTyped*/}
              {/*                strings={['user27@gmail.com']}*/}
              {/*                typeSpeed={100}*/}
              {/*                loop={false}*/}
              {/*            />*/}
              {/*        }*/}

              {/*    </TextTyping>*/}
              {/*</FooterInnerBg>*/}

              {/*    </FooterInner>*/}
              {/*</FooterPepperDex>*/}
              <FooterSocial>
                <FooterLeft>
                  <FooterSocialTop>
                    {/*<TextSign>{data.textSign}</TextSign>*/}
                    <FooterHeading
                      dangerouslySetInnerHTML={{ __html: data.heading }}
                      className={`${infiniteTitle}`}
                    />
                  </FooterSocialTop>
                  <FooterSocialInner>
                    <SocialItem>
                      <LinkSocial
                        href={data.social.discord.url}
                        target={"_blank"}
                        rel={"nofollow"}
                      >
                        <span>{data.social.discord.text}</span>
                        <Discord color="black" />
                      </LinkSocial>
                    </SocialItem>
                    <SocialItem>
                      <LinkSocial
                        href={data.social.twitter.url}
                        target={"_blank"}
                        rel={"nofollow"}
                      >
                        <span>{data.social.twitter.text}</span>
                        <Twitter color="black" />
                      </LinkSocial>
                    </SocialItem>
                  </FooterSocialInner>
                </FooterLeft>
                <FooterRight>
                  <PythSponsor>
                    <span>Powered by</span>
                    <a
                      href="https://pyth.network/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Pyth />
                    </a>
                    <a href="">
                      <Image>
                        <img src={"/home/masthead/hxro.png"} alt="hxro" style={{ height: "40px", width: "40px" }} />
                      </Image>
                    </a>
                  </PythSponsor>
                  <ButtonSignUp>
                    <a href={data.button.url} target={"_blank"}>
                      <Button $isSecondary={true}>
                        {data.button.text}
                        <ArrowRight />
                      </Button>
                    </a>
                  </ButtonSignUp>
                </FooterRight>
              </FooterSocial>
            </FooterContain>
          </Container>
        </Wrapper>
      </FooterWrap>
    </>
  );
};

export default Footer;
