import {
    ContainerSvgDraw, HeadingMasthead, Icon3D,
    MastheadWrapper, PepperdexTextWrapper, WelcomeDescription, WelcomeText,
} from "./style.js";
import {Container, Wrapper} from "../../../styles/global";
import {useState} from "react";
import {useEffect, useRef} from "react";

import Vivus from "vivus";
import {Logo, PepperdexTextBanner, PepperdexTextBannerMb} from "../../global/Icon/index.jsx";
import {gsap} from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import { DefaultPlayer as Video } from 'react-html5video';

gsap.registerPlugin(ScrollTrigger);
const Masthead = ({data}) =>{
    const svgDraw = useRef(null)
    const svgDrawMb = useRef(null)
    const [infinite,setInfinite] = useState('')
    const [checkMedia,setCheckMedia] = useState(false)
    const Icon3Dref = useRef(null)
    const titleWelcome = useRef(null)
    const descriptionWelcome = useRef(null)
    useEffect(()=>{
        let checkMediaQuery = window.matchMedia('(max-width: 767px)').matches
        setCheckMedia(checkMediaQuery)
        let SVGAnimation
        gsap.set(titleWelcome.current,{opacity:0})
        gsap.set(descriptionWelcome.current,{opacity: 0})
        if(svgDraw?.current && !checkMediaQuery){
            SVGAnimation = new Vivus(svgDraw.current, {duration: 100,type:'sync'}, null)
            SVGAnimation.play(1,function(){
                setInfinite('infinite')
                gsap.set(titleWelcome.current,{opacity:1})
                gsap.set(descriptionWelcome.current,{opacity: 1})
                setTimeout(()=>{
                    SVGAnimation.destroy()
                },325)
            })
        }else if (svgDrawMb?.current){
            SVGAnimation = new Vivus(svgDrawMb.current, {duration: 100,type:'sync'}, null)
            SVGAnimation.play(1,function(){
                setInfinite('infinite')
                gsap.set(titleWelcome.current,{opacity:1})
                gsap.set(descriptionWelcome.current,{opacity: 1})
                setTimeout(()=>{
                    SVGAnimation.destroy()
                },325)
            })
        }
    },[])
    useEffect(()=>{
        if(typeof window !== "undefined" && Icon3Dref?.current){
            const scroller = document.querySelector(".scroller")
            const VideoElm = document.querySelector('.icon-3d video')
            gsap.to(Icon3Dref.current,{
                scrollTrigger:{
                    trigger:Icon3Dref.current,
                    start: 'top top',
                    end:"bottom +=50%",
                    scroller: scroller,
                    scrub: 1,
                    onUpdate:(self)=>{
                        VideoElm.style.opacity = 1 - self.progress
                    }
                },
            })
        }
        ScrollTrigger.refresh()
    },[])
    return <MastheadWrapper background={true} className={'masthead-wrapper'}>
        <Wrapper className={`masthead-wrapper`}>
            <Container>
                <ContainerSvgDraw>
                    <WelcomeDescription ref={descriptionWelcome}>
                        <p dangerouslySetInnerHTML={{__html: data.description}} ></p>
                        <p dangerouslySetInnerHTML={{__html: data.descriptionMb}} ></p>
                    </WelcomeDescription>
                    <WelcomeText ref={titleWelcome}>
                        {
                            !checkMedia && <Logo />
                        }
                    </WelcomeText>
                    {
                        !checkMedia ? <svg className={`${infinite} stroke-line-desktop`} xmlns="http://www.w3.org/2000/svg" width={1360} height={500} viewBox="0 0 1360 500" fill="none" ref={svgDraw}>
                            <line className={'line-1'} x1="268.335" y1="0.522065" x2="678.097" y2="287.441" stroke="white" strokeOpacity="0.08" strokeDasharray="10 10"  />
                            <line className={'line-2'} x1="394.807" y1="498.725" x2="683.013" y2="296.922" stroke="white" strokeOpacity="0.08" strokeDasharray="10 10"  />
                            <line x1="-4.37114e-08" y1="288.5" x2={1360} y2="288.5" stroke="white" strokeOpacity="0.08" />
                            <line x1="678.5" y1={499} x2="678.5" y2="-2.18557e-08" stroke="white" strokeOpacity="0.08" />
                            <circle cx={680} cy={289} r={188} fill="#171717"/>
                            <path  className={'circle'} d="M868 289C868 251.817 856.974 215.469 836.316 184.553C815.659 153.636 786.297 129.54 751.944 115.311C717.592 101.081 679.791 97.3583 643.323 104.612C606.855 111.866 573.356 129.772 547.064 156.064C520.772 182.356 502.866 215.855 495.612 252.323C488.358 288.791 492.081 326.592 506.311 360.944C520.54 395.297 544.636 424.659 575.553 445.316C606.469 465.974 642.817 477 680 477" stroke="white" strokeOpacity="0.08" strokeDasharray="10 10" />
                        </svg> : <></>
                    }
                    <svg className={`${infinite} stroke-line-Mb`} ref={svgDrawMb} xmlns="http://www.w3.org/2000/svg" width={351} height={529} viewBox="0 0 351 529" fill="none">
                        <line className={'line-1'} x1="175.327" y1="353.915" x2="27.3678" y2="514.763" stroke="white" strokeOpacity="0.08" strokeDasharray="10 10" />
                        <line className={'line-2'} y1="-0.5" x2="218.55" y2="-0.5" transform="matrix(-0.677004 -0.735979 -0.735979 0.677004 174.959 339)" stroke="white" strokeOpacity="0.08" strokeDasharray="10 10" />
                        <line x1="4.37114e-08" y1="338.5" x2={351} y2="338.5" stroke="white" strokeOpacity="0.08" />
                        <line x1="174.5" y1={529} x2="174.5" y2="-2.18557e-08" stroke="white" strokeOpacity="0.08" />
                        <circle cx="175.5" cy="339.5" r="137.5" fill="#171717" />
                        <path className={'circle'} d="M313 339.5C313 312.305 304.936 285.721 289.827 263.109C274.718 240.497 253.244 222.874 228.119 212.467C202.994 202.06 175.347 199.337 148.675 204.642C122.003 209.947 97.5025 223.043 78.2728 242.273C59.0431 261.503 45.9475 286.003 40.642 312.675C35.3366 339.347 38.0595 366.994 48.4666 392.119C58.8736 417.244 76.4973 438.718 99.1091 453.827C121.721 468.936 148.305 477 175.5 477" stroke="white" strokeOpacity="0.08" strokeDasharray="10 10" />
                    </svg>
                        <Icon3D className={`${infinite} icon-3d`} ref={Icon3Dref} dangerouslySetInnerHTML={{__html: `<video autoPlay loop muted playsInline preload="none">
                                <source src="${data.images}"/>
                            </video>`}}>
                            {/*<picture>*/}
                            {/*    <source media="(min-width:768)" srcSet={data.images}/>*/}
                            {/*    <source media="(max-width:767px)" srcSet={data.imagesMb} />*/}
                            {/*    <img src={data.images} alt="hxro-pepperdex" ref={imageRef}/>*/}
                            {/*</picture>*/}
                        </Icon3D>
                </ContainerSvgDraw>
                <PepperdexTextWrapper className={infinite}>
                    <div>
                        <HeadingMasthead>{data.titleMasthead}</HeadingMasthead>
                    </div>
                </PepperdexTextWrapper>
            </Container>
        </Wrapper>

    </MastheadWrapper>
}

export default Masthead
