const KeyframeCss = `
@-webkit-keyframes scale-opacity {
  0% {
    -webkit-transform: scale(0.6);
            transform: scale(0.6);
            opacity:0;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
            opacity:1;
  }
}
@keyframes scale-opacity {
  0% {
    -webkit-transform: scale(0.6);
            transform: scale(0.6);
            opacity:0;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
            opacity:1;
  }
}
@keyframes draw-circle {
    to {
        stroke-dashoffset: -885.930419921875;
    }
}

@keyframes draw-line-1 {
    to {
        stroke-dashoffset: -500.2273254394531;
    }
}

@keyframes draw-line-2 {
    to {
        stroke-dashoffset: -351.833984375;
    }
}

@keyframes draw-line-bottom {
    from{
        width:0;
    }
    to{
        width:100%;
    }
}
`

export default KeyframeCss
