import styled from 'styled-components'
import tw from 'twin.macro'

export const HeaderWrap = styled.header`
    ${tw`pt-[20px] pb-[20px] lg:pt-[12px] lg:pb-[12px] z-[1] absolute w-full h-[max-content] font-manrope bg-[#171717] xxl:bg-[#1D1D1D] xxl:z-10`}
    ${(props)=>{
    if(props.$active){
        return {
        }
    }
}}
`
export const HeaderContain = styled.div`
    ${tw`flex justify-between items-center h-[61px] bg-transparent relative w-full
    before:bg-[rgba(255, 255, 255, 0.05)]
    before:z-[0]
    before:absolute
    before:w-full
    before:h-full
    before:top-[0]
    before:left-[0]
    `}
    &:before{
        clip-path: polygon(0% 0%,0% 70%,4% 100%,100% 100%,100% 30%,94% 0%);
        @media screen and (max-width:1280px){
            clip-path: polygon(0% 0%,0% 70%,7% 100%,100% 100%,100% 30%,94% 0%);
        }
    }

`

export const HeaderText = styled.p`
    ${tw`  inline-flex items-center text-white md:text-[8.95px] md:leading-[11.03px] relative h-full bg-white`}
    clip-path: polygon(0% 0%,0% 70%,30% 100%,100% 100%,100% 30%,70% 0%);
    flex: 0 0 74px;
    max-width: 74px;
    svg{
        ${tw`inline-block absolute top-[50%] left-[50%]`}
        transform: translate(-50%, -50%);
    }
`
export const HeaderLinks = styled.ul`
    ${tw`flex justify-end items-center xxl:hidden grid grid-cols-5 w-full h-full`}
`
export const HeaderLinkItem = styled.li`
    ${tw`text-white relative !ml-0 w-full h-full`}
    a{
        ${tw`text-[#FFFFFF66] no-underline text-[10px] font-bold leading-[110%] tracking-[-0.1px] inline-flex items-center uppercase w-full h-full items-end pb-[10px] pl-[10px] relative hover:text-white`}
        transition: all 0.3s ease;
        span{
            ${tw`mr-[15px]`}
        }
        svg{
            ${tw`absolute top-[50%] left-[50%]`} 
            transform: translate(-50%, -50%);
        }
    }
`
export const LinkSocial = styled.a`
    border: none;
    outline: none;
    position: relative;
    border-radius: 0;
    background: linear-gradient(to right, rgba(255, 255, 255, 0.09), rgba(255, 255, 255, 0));
    z-index: 0;
    &:before {
        content: "";
        position: absolute;
        top: 1px;
        right: 0;
        bottom: 1px;
        left: 1px;
        background-color: #232323;
        border-radius: 0;
        z-index: -1;
      }
`

export const MenuBtn = styled.div`
    ${tw`hidden xxl:flex items-center cursor-pointer z-10 pr-[30px]  pl-[30px] h-full bg-transparent`}
    clip-path: polygon(0% 0%,0% 100%,100% 100%,100% 100%,100% 30%,77% 0%);
`

export const ButtonSignUp  = styled.div`
    ${tw`cursor-pointer xxl:hidden`}
    flex: 0 0 264px;
    max-width: 264px;
    a{
        ${tw`no-underline`}
    }
`
