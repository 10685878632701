import { createGlobalStyle } from 'styled-components';
import {fontFaces} from "./fonts.css.js";
import KeyframeCss from "./keyframe.css.js";
import { injectGlobal } from '@emotion/css'

const GlobalStyle = () => injectGlobal`
    ${fontFaces}
    ${KeyframeCss}
  html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
    display: block;
}
body {
    line-height: 1;
    font-family: 'Orbitron',sans-serif;
    background: #171717;
    overflow:hidden;
}
img{
    width:100%;
    height:auto;
}
ol, ul {
    list-style: none;
}
blockquote, q {
    quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
    content: '';
    content: none;
}
table {
    border-collapse: collapse;
    border-spacing: 0;
}

.wallet-adapter-modal.wallet-adapter-modal-fade-in{
    height: 100vh;
}
.wallet-adapter-button-start-icon{
    img{
       width: 30px;
    }
}
*, :after, :before {
    box-sizing: border-box;
}

.connector,.wallet-key {
      color: #000000;
    border-color: #000000;
    background: #ffffff;
    border-radius: 5.70953px;
    padding: 20px 25px 16px !important;
    cursor: pointer;
    text-align: center;
    font-size: 18px;
    line-height: 12.65px;
    letter-spacing: -0.02em;
    display: inline-block;
    min-width: 200px;
    height: fit-content;
    text-decoration: none;
  span {
    font-size: 18px;
    line-height: 12.65px;
    letter-spacing: -0.02em;
  }
  height: fit-content;
  &:not(.wallet-key):hover, &:not(.wallet-key):focus {
        background: #000000;
    border: 1px solid #ffffff;
    color: #ffffff;
  }
}
button.wallet-key{
  color: @bg-color;
  border-color: @bg-color;
  background: @accent-color;
  &:hover, &:focus {
    color: @bg-color !important;
    border-color: @bg-color!important;
    background: @accent-color!important;
  }
}
button.secondary-button{

  border-color: @accent-color;
  background:  @bg-color;
  border-radius: 5.70953px;
  cursor: pointer;
  text-align: center;
  font-size: 13.56px;
  line-height: 12.65px;
  letter-spacing: -0.02em;
  margin-right: 16px;
  a {
    display: inline-block;
    padding: 20px 25px 16px;
    font-size: 13.56px;
    line-height: 12.65px;
    letter-spacing: -0.02em;
    color: @accent-color ;
  }
  height: fit-content;
  &:hover, &:focus {
    background:  @accent-color ;
    border-color: @bg-color;
    a{
      color: @bg-color;
    }
  }
}

/* Starts CTA  */

.stats-card{
  text-align: center;
  .stats-card-contain{
    padding-top: 23px;
    padding-bottom: 23px;
    max-width:601px;
    border: 1px solid #707070;
    border-radius: 9px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 32px;
  }
  .stats-card-heading{

    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    margin-bottom: 23px;
    line-height: 33px;
    color: #FFFFFF;
  }
  .stats-card-number{

    font-style: normal;
    font-weight: 400;
    font-size: 39px;
    line-height: 33px;
    text-align: center;
    letter-spacing: 0.04em;
    color: #FFFFFF;
  }
}
.scroll-content{
    height:100vh;
}
/* Starts CTA  */
.stats-logo{
  text-align: center;
  margin-bottom: 32px;
  .stats-logo-wrapper{
    max-width: 845px;
    margin: 0 auto;
    img{
        width:441px;
        height:260px;
        object-fit:cover;
    }
    h1{
      text-align: center;
      font-style: normal;
      font-weight: 400;
      font-size: 41.5614px;
      line-height: 46px;
      letter-spacing: 0.33em;
      text-transform: uppercase;
      margin:20px 0 30px;
    }
    p{
      font-style: normal;
      font-weight: 400;
      font-size: 28px;
      line-height: 33px;
      color: #FFFFFF;
    }
  }
}
@-webkit-keyframes fade-in-top {
      0% {
        -webkit-transform: translateY(-50px);
                transform: translateY(-50px);
        opacity: 0;
      }
      100% {
        -webkit-transform: translateY(0);
                transform: translateY(0);
        opacity: 1;
      }
    }
    @keyframes fade-in-top {
      0% {
        -webkit-transform: translateY(-50px);
                transform: translateY(-50px);
        opacity: 0;
      }
      100% {
        -webkit-transform: translateY(0);
                transform: translateY(0);
        opacity: 1;
      }
    }
    .hxro-background-overlay-navbar{
        &.hidden{
            display:none;
        }
        &.block{
            display:block;
        }
    }
`;

export default GlobalStyle;
