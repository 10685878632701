import header from './header.json'
import footer from './footer.json'
import popup from './popup.json'
import popupTrade from './trade-popup.json'

const Global = {
    header,
    footer,
    popup,
    popupTrade
}

export default Global
