import {
    Nav,
    NavItem,
    NavItemTextTop,
    NavWrap,
    NavSocialItem,
    NavSocial,
    LinkSocial,
    ButtonSignUp
} from "./style.js";
import * as Icon from "../Icon/index.jsx";
import { useGlobalState} from "../../../stores.js";
import Button from "../Button/index.jsx";

const Navbar = ({data}) => {

    let unique = Date.now()

    const [value] = useGlobalState('navbar')


    return <>
        <Nav $active={value}>
            <NavWrap>
                {
                    data.navbar_desktop.items.map((item,index) => {

                        return <NavItem key={`navbar-item-link-${unique}-${index}`}>
                            {
                                item?.url ? <>
                                    <a href={item.url} target={item.target && item.target !== '' ? item.target : '_blank'} rel={'nofollow'}>
                                        <span>{item.number}</span>
                                        <NavItemTextTop dangerouslySetInnerHTML={{__html:item.title}} />
                                    </a>
                                </> : <>
                                    <span>{item.number}</span>
                                    <NavItemTextTop dangerouslySetInnerHTML={{__html:item.title}} />
                                </>
                            }
                        </NavItem>
                    })
                }
                 <NavSocial>
                    <NavSocialItem>
                        <LinkSocial
                            href={data.social.discord.url}
                            target={"_blank"}
                            rel={"nofollow"}
                        >
                            <span>{data.social.discord.text}</span>
                            <Icon.Discord />
                        </LinkSocial>
                    </NavSocialItem>
                    <NavSocialItem>
                        <LinkSocial
                            href={data.social.twitter.url}
                            target={"_blank"}
                            rel={"nofollow"}
                        >
                            <span>{data.social.twitter.text}</span>
                            <Icon.Twitter />
                        </LinkSocial>
                    </NavSocialItem>
                </NavSocial>
            </NavWrap>
            <ButtonSignUp>
                <a href={data.button.url} target={'_blank'}>
                    <Button $isSecondary={true}>
                        {data.button.text}
                        <Icon.ArrowRight/>
                    </Button>
                </a>
              </ButtonSignUp>
        </Nav>
    </>
}

export default Navbar
