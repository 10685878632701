import GlobalStyle from "./styles/global.css.js";
import Template from "./_template.jsx";
import Home from "./pages/Home.jsx";
import Document from "./_document.jsx";

import {
  BrowserRouter,
  BrowserRouter as Router,
  HashRouter,
  Route,
  Switch,
} from "react-router-dom";

function App({ global }) {
  return (
    <>
      <Document />
      <GlobalStyle />
      <Template data={global}>
        <BrowserRouter>
          <Switch>
            <Route exact path="/">
              {<Home />}
            </Route>
            <Route
              exact
              path="/docs"
              component={() => {
                window.location.href =
                  "https://pepperdex.gitbook.io/pepperdex-2.0-docs/";
                return null;
              }}
            />
          </Switch>
        </BrowserRouter>
      </Template>
    </>
  );
}

export default App;
