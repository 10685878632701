import tw from "twin.macro";
import styled from "styled-components";

export const Nav = styled.div`
  ${tw`absolute left-[0] top-[82px] px-[12px] pt-[8px] pb-[12px] lg:top-[85px] w-full bg-[#1D1D1D] transition-all duration-300 block z-50`}
  clip-path: polygon(0% 0%,0% 100%,100% 100%,94% 100%,100% 94%,100% 0%);
  @media screen and (max-width:1280px){
    clip-path: polygon(0% 0%,0% 100%,100% 100%,98% 100%,100% 94%,100% 0%);
  }
  @media screen and (max-width:768px){
    clip-path: polygon(0% 0%,0% 100%,100% 100%,94% 100%,100% 94%,100% 0%);
    }
  ${(props) => {
    if (props.$active) {
      return {
        ...tw`opacity-100`,
        transform: "translateX(0)",
      };
    } else {
      return {
        ...tw`opacity-0`,
        transform: "translateX(100%)",
      };
    }
  }}
`;
export const NavWrap = styled.div`
  ${tw`flex flex-col justify-end items-center w-full h-full`}
`;
export const NavSocialItem = styled.div`
  ${tw`text-white relative !ml-0 w-full h-full`}
  a {
    ${tw`text-[#FFFFFF66] no-underline  text-[10px] font-bold leading-[110%] tracking-[-0.1px] inline-flex items-center uppercase w-full h-full items-end pb-[10px] pl-[10px] relative hover:text-white`}
    transition: all 0.3s ease;
    span {
      ${tw`mr-[15px]`}
    }
    svg {
      ${tw`absolute top-[50%] left-[50%] xxl:w-[20px] xxl:h-[20px]`}
      transform: translate(-50%, -50%);
    }
  }
`;
export const NavSocial = styled.div`
    ${tw`flex h-[61px] w-full mt-[20px]`}
`;
export const NavItem = styled.div`
  ${tw`text-white relative !ml-0 w-full h-full cursor-pointer`}
  a {
    ${tw`
        text-[#FFFFFF66]
        no-underline
        text-[10px]
        font-bold
        leading-[110%]
        tracking-[-0.1px]
        inline-flex
        uppercase
        w-full
        items-end
        pb-[10px]
        pl-[12px]
        pr-[12px]
        relative
        hover:text-white
        h-[61px]
        border-b
        border-b-[1px]
        border-solid
        border-[#696767]
    `}
    transition: all 0.3s ease;
    span {
      ${tw`mr-[15px]`}
    }
    svg {
      ${tw`absolute top-[50%] left-[50%]`}
      transform: translate(-50%, -50%);
    }
  }
`;
export const LinkSocial = styled.a`
  ${tw`border border-solid bg-transparent`}
  border: none;
  outline: none;
  position: relative;
  border-radius: 0;
  background: linear-gradient(to right, rgba(255, 255, 255, 0.09), rgba(255, 255, 255, 0));
  z-index: 0;

  &:before {
    content: "";
    position: absolute;
    top: 1px;
    right: 0;
    bottom: 1px;
    left: 1px;
    background-color: #1D1D1D;
    border-radius: 0;
    z-index: -1;
  }
`;
export const NavItemTextTop = styled.div``;

export const ButtonSignUp  = styled.div`
    ${tw`cursor-pointer pt-[40px] w-full`}
    a{
        ${tw`no-underline`}
    }
`