import {
    PopUpWrap,
} from "./style.js";
import {setPopUp, useGlobalState} from "../../../stores.js";
import usePreventScrolling from "../../../stores/usePreventScrolling.jsx";
import PopUpContent from "./PopUpContent.jsx";

const PopUp = ({data}) => {

    let [active] = useGlobalState('popup')

    usePreventScrolling({active})

    return <>
        <PopUpWrap $active={active}>
            {{ 'default': <PopUpContent data={data['default']} />,
                'trade': <PopUpContent data={data['trade']} />
            }[active]}
        </PopUpWrap>
    </>
}

export default PopUp
